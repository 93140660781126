import React, { useState } from "react"

import Alert from "@kiwicom/orbit-components/lib/Alert"
import Button from "@kiwicom/orbit-components/lib/Button"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"

import { parseErrorMessage } from "api/errors"

import {
  EmberModal,
  EmberModalHeader,
  EmberModalSection,
} from "components/generic/ember-modal"

import { asyncFetchFromAPIBase } from "utils/fetch-utils"

import { ServiceUpdateDetailsPayload } from "./models"

interface ServiceUpdateDeletionModalType {
  serviceUpdate: ServiceUpdateDetailsPayload
  handleClose: () => void
  handleSuccess: () => void
}

const ServiceUpdateDeletionModal = ({
  serviceUpdate,
  handleClose,
  handleSuccess,
}: ServiceUpdateDeletionModalType): JSX.Element => {
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState(null)

  return (
    <EmberModal size="small" onClose={handleClose}>
      <EmberModalHeader title="Delete Service Update" />
      <EmberModalSection>
        <Stack spacing="large">
          <Text>
            Are you sure you want to delete{" "}
            {serviceUpdate.shortMessage ? (
              <Text as="span" weight="bold">
                {serviceUpdate.shortMessage}
              </Text>
            ) : (
              "this service update"
            )}
            ?
          </Text>
          <Button
            fullWidth={true}
            type="critical"
            disabled={submitting}
            loading={submitting}
            onClick={() =>
              submitDeletionRequest(
                handleSuccess,
                serviceUpdate.uid,
                setSubmitting,
                setError
              )
            }
            dataTest="confirm"
          >
            Delete
          </Button>
          {error && <Alert type="critical">{error}</Alert>}
        </Stack>
      </EmberModalSection>
    </EmberModal>
  )
}

async function submitDeletionRequest(
  handleSuccess,
  uid,
  setSubmitting,
  setError
) {
  setSubmitting(true)
  const response = await asyncFetchFromAPIBase({
    path: "/v1/service-updates/admin/",
    method: "DELETE",
    body: { uid },
  })
  setSubmitting(false)
  if (response.error) {
    setError(`
      Failed to create the service update. \
      The error message was "${parseErrorMessage(response)}" \
    `)
  } else {
    setError(null)
    handleSuccess()
  }
}

export default ServiceUpdateDeletionModal
