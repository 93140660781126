// @ts-strict-ignore
import React from "react"

import { subHours } from "date-fns"
import { isBefore } from "date-fns/esm"
import { Form, Formik } from "formik"
import moment from "moment"

import Alert from "@kiwicom/orbit-components/lib/Alert"
import Button from "@kiwicom/orbit-components/lib/Button"
import Checkbox from "@kiwicom/orbit-components/lib/Checkbox"
import Select from "@kiwicom/orbit-components/lib/Select"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"
import Textarea from "@kiwicom/orbit-components/lib/Textarea"

import { parseErrorMessage } from "api/errors"

import {
  EmberModal,
  EmberModalHeader,
  EmberModalSection,
} from "components/generic/ember-modal"
import {
  EmberDateInput,
  EmberTextArea,
  EmberTextInput,
} from "components/generic/formik-inputs"

import Yup from "logic/validation/yup-extended"

import { asyncFetchFromAPIBase } from "utils/fetch-utils"
import { capitalizeFirstLetter, snakeCaseToTitleCase } from "utils/string-utils"
import { sortBy } from "utils/struct-utils"

import { ServiceUpdateDetailsPayload } from "./models"

interface ServiceUpdateDetailsModalType {
  config: any
  action: "create" | "edit"
  initialValues: Record<string, any> | null
  handleClose: () => void
  handleSuccess: () => void
}

const ServiceUpdateDetailsModal = ({
  config,
  action,
  initialValues,
  handleClose,
  handleSuccess,
}: ServiceUpdateDetailsModalType): JSX.Element => {
  preprocessConfig(config)
  return (
    <EmberModal size="small" onClose={handleClose} preventOverlayClose={true}>
      <EmberModalHeader
        title={`${capitalizeFirstLetter(action || "create")} Service Update`}
      />
      <EmberModalSection>
        <Stack spacing="large">
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={yupValidationSchema()}
            onSubmit={(
              values: ServiceUpdateDetailsPayload,
              { setSubmitting, setStatus }
            ) =>
              submitDetailsRequest(
                action,
                handleSuccess,
                values,
                setSubmitting,
                setStatus
              )
            }
          >
            {(formikProps) => formBody(config, action, formikProps)}
          </Formik>
        </Stack>
      </EmberModalSection>
    </EmberModal>
  )
}

const yupValidationSchema = () =>
  Yup.object({
    shortMessage: Yup.string().required("A short message is required"),
    detailedMessageMd: Yup.string(),
    startTime: Yup.date()
      .required("A start time is required")
      .typeError("A start time is required"),
    endTime: Yup.date()
      .required("An end time is required")
      .typeError("An end time is required")
      .test(
        "end is gt start",
        "The end time must be after the start time",
        function (endTime) {
          return moment(endTime).isAfter(this.parent.startTime)
        }
      ),
  })

const formBody = (config, action, formikProps) => {
  const { isSubmitting, setFieldValue, status, values } = formikProps
  return (
    <Form>
      <Stack spacing="large">
        <Select
          name="type"
          label="Service Update Type"
          options={config.type.enum.map((option) => ({
            label: snakeCaseToTitleCase(option.name),
            value: option.value,
          }))}
          value={values.type}
          onChange={(event) =>
            setFieldValue("type", parseInt(event.currentTarget.value))
          }
        />
        <EmberTextInput
          name="shortMessage"
          help="Will be displayed in the service update bar on the home page"
          helpClosable={false}
          actionLink={{
            label: "Insert Template",
            onClick: () =>
              setFieldValue(
                "shortMessage",
                "Service Update: Major delays to some services"
              ),
          }}
          label="Short Message"
        />
        <EmberTextArea
          name="detailedMessageMd"
          label="Detailed Message"
          value={values.detailedMessageMd}
          help="Will be displayed on the service updates page. Markdown supported."
          helpClosable={false}
          actionLink={{
            label: "Insert Template",
            onClick: () =>
              setFieldValue(
                "detailedMessageMd",
                "There is currently heavy snow along the route which is liable to cause major delays. We'll keep this page updated with any specific trip information when we have it."
              ),
          }}
          onChange={(event) =>
            setFieldValue("detailedMessageMd", event.currentTarget.value)
          }
          rows={4}
        />
        <Textarea
          name="internalNotesMd"
          label="Internal Notes"
          help="Never displayed publicly. Markdown supported."
          helpClosable={false}
          value={values.internalNotesMd}
          placeholder=""
          onChange={(event) =>
            setFieldValue("internalNotesMd", event.currentTarget.value)
          }
        />
        <EmberDateInput
          name="startTime"
          label="Start"
          showTime
          actionLink={{
            label: "Set Current Time",
            onClick: () => setFieldValue("startTime", new Date()),
          }}
        />
        {values.startTime &&
          isBefore(values.startTime, subHours(new Date(), 1)) && (
            <Alert type="warning">
              The start time is in the past. Make sure it's correct
            </Alert>
          )}
        <EmberDateInput name="endTime" label="End" showTime />
        {values.startTime && values.endTime && (
          <Text>
            Duration:{" "}
            {moment
              .duration(moment(values.endTime).diff(values.startTime))
              .humanize()}
          </Text>
        )}
        <Checkbox
          name="isSystemOverwritable"
          label="Allow the system to override message with automatic updates"
          checked={values.isSystemOverwritable}
          onChange={(event) =>
            setFieldValue("isSystemOverwritable", event.currentTarget.checked)
          }
        />
        <Button
          disabled={isSubmitting}
          loading={isSubmitting}
          fullWidth={true}
          submit={true}
          dataTest="submit"
        >
          {`${capitalizeFirstLetter(action)} Service Update`}
        </Button>
        {status?.error && <Alert type="critical">{status.error}</Alert>}
      </Stack>
    </Form>
  )
}

async function submitDetailsRequest(
  action,
  handleSuccess,
  values: ServiceUpdateDetailsPayload,
  setSubmitting,
  setStatus
) {
  setSubmitting(true)
  setStatus({
    success: false,
    error: null,
  })
  const response = await asyncFetchFromAPIBase({
    path: "/v1/service-updates/admin/",
    method: action === "create" ? "POST" : "PATCH",
    body: formatRequestPayload(values),
  })
  setSubmitting(false)
  if (response.error) {
    setStatus({
      success: false,
      error: `
      Failed to create the service update. \
      The error message was "${parseErrorMessage(response)}" \
      `,
    })
  } else {
    handleSuccess(response.epoch)
  }
}

// Takes a raw object with each field's value, and returns the JSON object that
// gets sent to the backend
function formatRequestPayload(values) {
  const payload = {
    // ensure these instead of nulls
    internalNotesMd: "",
    isSystemOverwritable: false,

    ...values,

    // The frontend displays & handles datetimes in the user's timezone,
    // because that's what's intuitive to them, but we submit them to the
    // backend as UTC-based ISO-formatted strings, to avoid any ambiguity.
    startTime: values.startTime.toISOString(),
    endTime: values.endTime.toISOString(),
  }

  // Remove values that the backend doesn't need from us
  for (const key of [
    "id",
    "detailedMessageRendered",
    "internalNotesRendered",
    "preview",
    "updateTime",
  ]) {
    delete payload[key]
  }

  return payload
}

function preprocessConfig(config) {
  // Remove "trip update" from the service update type list, since this GUI is
  // for "general" service updates only (i.e. not attached to a specific trip)
  config.type.enum = config.type.enum.filter((t) => t.name != "TRIP_UPDATE")

  // Make "other" the last option, keep the rest alphabetical
  sortBy(config.type.enum, (type: { name: string }) =>
    type.name == "OTHER" ? "~last" : type.name
  )
}

export default ServiceUpdateDetailsModal
