// @ts-strict-ignore
import { addHours } from "date-fns"
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input/input-max"
import * as Yup from "yup"
import { AnyObject, Maybe } from "yup/lib/types"

// .phoneNumber check confirms it's a valid number

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  "phoneNumber",
  function (message?: string) {
    return this.test("phone-number", message, function (value) {
      if (
        value != null &&
        (isPossiblePhoneNumber(value) == false ||
          isValidPhoneNumber(value) == false)
      ) {
        return false
      } else {
        return true
      }
    })
  }
)

// .mobileNumber check confirms it's a valid number and is a mobile phone

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  "mobileNumber",
  function (message?: string) {
    return this.test("mobile-number", message, function (value) {
      return (
        value == null ||
        (isPossiblePhoneNumber(value) &&
          isValidPhoneNumber(value) &&
          ["MOBILE", "FIXED_LINE_OR_MOBILE"].includes(
            parsePhoneNumber(value).getType()
          ))
      )
    })
  }
)

declare module "yup" {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends Yup.BaseSchema<TType, TContext, TOut> {
    mobileNumber(message?: string): StringSchema<TType, TContext>
    phoneNumber(message?: string): StringSchema<TType, TContext>
  }
}

// Function for validating single mobile number outside of Formik
export async function validateMobileNumber(number: string): Promise<boolean> {
  const mobileNumberScheme = Yup.string().mobileNumber()
  const check = await mobileNumberScheme.isValid(number)
  return check
}

// .endDateGreaterThanStart takes the name of the start date property,
// and validates that end > start.

Yup.addMethod<Yup.DateSchema>(
  Yup.date,
  "endDateGreaterThanStart",
  function (
    startDatePropertyName: string,
    openEndPropertyName?: string,
    nullable?: boolean
  ) {
    return this.test("time-validation", "Invalid date", function (end) {
      if (nullable && !end) return true
      const start = this.parent[startDatePropertyName]
      const openEnd = openEndPropertyName && this.parent[openEndPropertyName]
      if (openEnd) return true
      if (end <= start) {
        return this.createError({
          message: "The end date must be after the start date",
        })
      } else {
        return true
      }
    })
  }
)

// .maxLength validates the max length of a date range

Yup.addMethod<Yup.DateSchema>(
  Yup.date,
  "maxLength",
  function (
    startDatePropertyName: string,
    maxHours: number,
    message?: string,
    nullable?: boolean
  ) {
    return this.test(
      "max-range-length-validation",
      "Range too long",
      function (end) {
        if (nullable && !end) return true
        const start = this.parent[startDatePropertyName]
        if (end > start && end > addHours(start, maxHours)) {
          return this.createError({
            message: message
              ? message
              : `Cannot be more than ${maxHours} hours long`,
          })
        } else {
          return true
        }
      }
    )
  }
)

// .maxDifference validates the max difference between dates

Yup.addMethod<Yup.DateSchema>(
  Yup.date,
  "maxDifference",
  function (
    otherDatePropertyName: string,
    maxDiffHours: number,
    message: string,
    nullable?: boolean
  ) {
    return this.test(
      "max-dates-diff-validation",
      "Dates too far",
      function (dateA) {
        if (nullable && !dateA) return true
        const dateB = this.parent[otherDatePropertyName]
        if (
          dateA < addHours(dateB, -1 * maxDiffHours) ||
          dateA > addHours(dateB, maxDiffHours)
        ) {
          return this.createError({
            message: message,
          })
        } else {
          return true
        }
      }
    )
  }
)

declare module "yup" {
  interface DateSchema<
    TType extends Maybe<Date>,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends Yup.BaseSchema<TType, TContext, TOut> {
    endDateGreaterThanStart(
      startDatePropertyName: string,
      openEndPropertyName?: string,
      nullable?: boolean
    ): DateSchema<TType, TContext>
    maxLength(
      startDatePropertyName: string,
      maxHours: number,
      message?: string,
      nullable?: boolean
    ): DateSchema<TType, TContext>
    maxDifference(
      otherDatePropertyName: string,
      maxDiffHours: number,
      message: string,
      nullable?: boolean
    ): DateSchema<TType, TContext>
  }
}

export default Yup
