// @ts-strict-ignore
import React, { useEffect, useState } from "react"

import { isBefore } from "date-fns"
import { formatInTimeZone } from "date-fns-tz"
import { endOfDay } from "date-fns/esm"
import moment from "moment-timezone"
import styled from "styled-components"

import { Edit, Remove } from "@kiwicom/orbit-components/icons"
import Alert from "@kiwicom/orbit-components/lib/Alert"
import Badge from "@kiwicom/orbit-components/lib/Badge"
import Button from "@kiwicom/orbit-components/lib/Button"
import Card, { CardSection } from "@kiwicom/orbit-components/lib/Card"
import Checkbox from "@kiwicom/orbit-components/lib/Checkbox"
import Heading from "@kiwicom/orbit-components/lib/Heading"
import Loading from "@kiwicom/orbit-components/lib/Loading"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"
import TextLink from "@kiwicom/orbit-components/lib/TextLink"

import { parseErrorMessage } from "api/errors"

import Container from "components/container"
import { MultilineText } from "components/generic/multiline-text"
import { AdminLayout } from "components/layout-custom"
import { ServiceUpdateDetailsPayload } from "components/service-update/models"
import ServiceUpdateDeletionModal from "components/service-update/service-update-deletion-modal"
import ServiceUpdateDetailsModal from "components/service-update/service-update-details-modal"

import { getDefaultTimezone } from "utils/date-utils"
import { fetchFromAPIBase } from "utils/fetch-utils"
import { capitalizeFirstLetter } from "utils/string-utils"

const Page = (): JSX.Element => {
  // `loadingError` is a string which, if set, indicates a global error
  // preventing us from even displaying the page
  const [loadingError, setLoadingError] = useState(null)

  // `selectedEpochs` maps each epoch name to a boolean indicating whether
  // we're currently showing service updates from that epoch. Only `past` is
  // actually togglable via the GUI.
  const [selectedEpochs, setSelectedEpochs] = useState({
    future: true,
    active: true,
    past: false,
  })

  // `totalByEpoch` maps each epoch name to a number indicating how many
  // service updates are in this epoch.
  const [totalByEpoch, setTotalByEpoch] = useState({
    future: 0,
    active: 0,
    past: 0,
  })

  // `updatesByEpoch` maps each epoch name to is an array of all system updates
  // in that epoch. The whole object may be `null`, meaning the data isn't
  // loaded yet.
  const [updatesByEpoch, setUpdatesByEpoch] = useState(null)

  // config contains options such as values for the modal drop-downs
  const [config, setConfig] = useState(null)

  // `detailsModalProps`, if non-null, means the details modal should be shown,
  // and those params are passed to it. If null, the modal is hidden. Same
  // logic for `deletionModalProps`
  const [detailsModalProps, setDetailsModalProps] = useState(null)
  const [deletionModalProps, setDeletionModalProps] = useState(null)

  // `reloadCount` is used to force a re-load of the updates data, when we know
  // it has changed, e.g. after the "create" or "edit" operation was just
  // performed
  const [reloadCount, setReloadCount] = useState(0)

  useEffect(() => {
    setUpdatesByEpoch(null) // while loading
    fetchServiceUpdateData(
      setTotalByEpoch,
      setUpdatesByEpoch,
      setConfig,
      setLoadingError,
      selectedEpochs
    )
  }, [reloadCount, selectedEpochs])

  const openCreationModal = () => {
    setDetailsModalProps({
      action: "create",
      initialValues: {
        type: config.type.enum[0].value,
        shortMessage: "",
        detailedMessageMd: "",
        internalNotesMd: "",
        startTime: new Date(),
        endTime: endOfDay(new Date()),
        isSystemOverwritable: true,
      },
    })
  }

  const openEditModal = (serviceUpdate: ServiceUpdateDetailsPayload) => {
    setDetailsModalProps({
      action: "edit",
      initialValues: serviceUpdate,
    })
  }

  const openDeletionModal = (serviceUpdate: ServiceUpdateDetailsPayload) => {
    setDeletionModalProps({ serviceUpdate })
  }

  return (
    <AdminLayout title="Service Updates">
      {detailsModalProps && (
        <ServiceUpdateDetailsModal
          {...detailsModalProps}
          config={config}
          handleClose={() => setDetailsModalProps(null)}
          handleSuccess={(epoch) => {
            setDetailsModalProps(false)
            // Ensure the epoch that the just-created update falls is is
            // visible. This also triggers a refresh of the data
            setSelectedEpochs({ ...selectedEpochs, [epoch]: true })
          }}
        />
      )}
      {deletionModalProps && (
        <ServiceUpdateDeletionModal
          {...deletionModalProps}
          handleClose={() => setDeletionModalProps(null)}
          handleSuccess={() => {
            setDeletionModalProps(null)
            setReloadCount(reloadCount + 1)
          }}
        />
      )}
      <Stack spacing="XLarge" align="center" direction="column">
        <Container>
          <Stack
            direction="column"
            justify="between"
            align="start"
            desktop={{ direction: "row", align: "center" }}
          >
            <Stack>
              <Text>
                Active updates are visible publicly on the{" "}
                <TextLink href="/service-updates/">
                  service updates page
                </TextLink>
              </Text>
            </Stack>
            <Stack
              direction="row"
              align="center"
              justify="between"
              desktop={{
                justify: "end",
                inline: true,
                shrink: true,
              }}
            >
              <div>
                <Checkbox
                  checked={!!selectedEpochs.past}
                  onChange={(event) =>
                    setSelectedEpochs({
                      ...selectedEpochs,
                      past: event.currentTarget.checked,
                    })
                  }
                  label={
                    "Show" +
                    ("past" in totalByEpoch ? ` ${totalByEpoch.past} ` : " ") +
                    "past service update" +
                    (totalByEpoch.past === 1 ? "" : "s")
                  }
                />
              </div>
              <Button
                size="small"
                onClick={() => openCreationModal()}
                dataTest="create"
              >
                Add Update
              </Button>
            </Stack>
          </Stack>
        </Container>
        <Container unpaddedOnMobile>
          <Stack>
            {renderPageBody(
              openEditModal,
              openDeletionModal,
              setSelectedEpochs,
              selectedEpochs,
              totalByEpoch,
              updatesByEpoch,
              loadingError
            )}
          </Stack>
        </Container>
      </Stack>
    </AdminLayout>
  )
}

const renderPageBody = (
  openEditModal,
  openDeletionModal,
  setSelectedEpochs,
  selectedEpochs: Record<string, boolean>,
  totalByEpoch: Record<string, number>,
  updatesByEpoch: Record<string, Array<ServiceUpdateDetailsPayload>>,
  loadingError: string | null
): JSX.Element => {
  if (loadingError) {
    return (
      <CardSection>
        <Alert type="critical">{loadingError}</Alert>
      </CardSection>
    )
  }

  if (updatesByEpoch === null) {
    // no error and no list of updates: we're currently loading
    return (
      <Stack align="center" justify="center">
        <Loading type="inlineLoader" />
      </Stack>
    )
  }

  const numServiceUpdatesToDisplay = ["future", "active", "past"]
    .filter((epoch) => selectedEpochs[epoch] && updatesByEpoch[epoch])
    .reduce((sum, epoch) => sum + updatesByEpoch[epoch].length, 0)

  if (numServiceUpdatesToDisplay === 0) {
    return (
      <Alert type="info" dataTest="alert-no-services-to-display">
        There are no{" "}
        {["active", "future", "past"]
          .filter((epoch) => selectedEpochs[epoch])
          .join(", ")
          .replace(/, ([^,]+)$/, " or $1")}{" "}
        updates at the moment
      </Alert>
    )
  }

  return (
    <>
      {["active", "future", "past"]
        .filter((epoch) => selectedEpochs[epoch] && updatesByEpoch[epoch])
        .map((epoch) =>
          updatesByEpoch[epoch]
            .sort((a, b) => (isBefore(a.startTime, b.startTime) ? -1 : 1))
            .map((serviceUpdate: ServiceUpdateDetailsPayload) => (
              <ServiceUpdateCard
                key={serviceUpdate.uid}
                openEditModal={openEditModal}
                openDeletionModal={openDeletionModal}
                serviceUpdate={serviceUpdate}
                epoch={epoch}
              />
            ))
        )}
    </>
  )
}

const ServiceUpdateCardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 4em;
`

const ServiceUpdateCard = ({
  openEditModal,
  openDeletionModal,
  serviceUpdate,
  epoch,
}) => (
  <Card>
    <CardSection>
      <ServiceUpdateCardContainer>
        <Stack>
          <Badge
            type={
              {
                future: "infoSubtle",
                past: "neutral",
                active: "successSubtle",
              }[epoch]
            }
          >
            {`${capitalizeFirstLetter(epoch)} update`}
          </Badge>
          <Stack spacing="XXSmall">
            <Text>
              Start:{" "}
              {formatInTimeZone(
                serviceUpdate.startTime,
                getDefaultTimezone(),
                "eee d MMM yyyy, HH:mm:ss"
              )}
            </Text>
            <Text>
              End:{" "}
              {formatInTimeZone(
                serviceUpdate.endTime,
                getDefaultTimezone(),
                "eee d MMM yyyy, HH:mm:ss"
              )}
            </Text>
            <Text>
              Duration:{" "}
              {capitalizeFirstLetter(
                moment
                  .duration(
                    moment
                      .tz(serviceUpdate.endTime, getDefaultTimezone())
                      .diff(
                        moment.tz(serviceUpdate.startTime, getDefaultTimezone())
                      )
                  )
                  .humanize()
              )}
              {epoch === "future"
                ? `, starts ${moment
                    .tz(serviceUpdate.startTime, getDefaultTimezone())
                    .fromNow()}`
                : null}
              {epoch === "past"
                ? `, ended ${moment
                    .tz(serviceUpdate.endTime, getDefaultTimezone())
                    .fromNow()}`
                : null}
            </Text>
          </Stack>
          <Heading as="h3" type="title3">
            Short Message:
          </Heading>
          <Text>{serviceUpdate.shortMessage}</Text>
          <Heading as="h3" type="title3">
            Detailed Message:
          </Heading>
          <MultilineText
            dangerouslySetInnerHTML={{
              __html: serviceUpdate.detailedMessageRendered,
            }}
          />
          {serviceUpdate.internalNotesMd && (
            <>
              <Heading as="h3" type="title3">
                Internal Notes:
              </Heading>
              <div
                dangerouslySetInnerHTML={{
                  __html: serviceUpdate.internalNotesRendered,
                }}
              />
            </>
          )}
        </Stack>
        <Stack>
          <Button
            type="primarySubtle"
            onClick={() => openEditModal(serviceUpdate)}
            dataTest="edit"
          >
            <Edit />
          </Button>
          <Button
            type="criticalSubtle"
            onClick={() => openDeletionModal(serviceUpdate)}
            dataTest="delete"
          >
            <Remove />
          </Button>
        </Stack>
      </ServiceUpdateCardContainer>
    </CardSection>
  </Card>
)

const fetchServiceUpdateData = (
  setTotalByEpoch,
  setUpdatesByEpoch,
  setConfig,
  setLoadingError,
  selectedEpochs
) => {
  const queryString = Object.entries(selectedEpochs)
    .filter(([, isSelected]) => isSelected)
    .map(([epoch]) => `epoch=${epoch}`)
    .join("&")
  const sub = fetchFromAPIBase({
    path: `/v1/service-updates/admin/?${queryString}`,
  }).subscribe((payload) => {
    if (payload.generalUpdates) {
      vivifyServiceUpdates(payload)
      setTotalByEpoch(
        Object.fromEntries(
          Object.entries(payload.generalUpdates).map(
            ([epoch, epochData]: Array<any>) => [epoch, epochData.total || 0]
          )
        )
      )
      setUpdatesByEpoch(
        Object.fromEntries(
          Object.entries(payload.generalUpdates).map(
            ([epoch, epochData]: any) => [epoch, epochData.serviceUpdates]
          )
        )
      )
      setConfig(payload.config)
      setLoadingError(null)
    } else {
      setUpdatesByEpoch(null)
      setConfig(null)
      setLoadingError(parseErrorMessage(payload))
    }
  })
  return () => sub.unsubscribe()
}

const vivifyServiceUpdates = (payload) => {
  // Takes the raw payload obtained from the backend and modifies it in place
  // to ensure it contains proper ServiceUpdateDetailsPayload objects.

  const updateTypesByName = Object.fromEntries(
    payload.config.type.enum.map(({ name, value }) => [
      name.toLowerCase(),
      value,
    ])
  )

  Object.values(payload.generalUpdates).forEach(
    (epochData: { serviceUpdates: Array<ServiceUpdateDetailsPayload> }) => {
      ;(epochData.serviceUpdates || []).forEach((serviceUpdate) => {
        // In the responses that it sends out, the backend emits update types as
        // strings (b/c that's how `ServiceUpdate.to_presentational_dict` was
        // originally built) but in requests sent to it, it expects types as
        // ints. Convert them to ints now.
        serviceUpdate.type = updateTypesByName[serviceUpdate.type]

        serviceUpdate.startTime = new Date(serviceUpdate.startTime)
        serviceUpdate.endTime = new Date(serviceUpdate.endTime)
      })
    }
  )
}

export default Page
