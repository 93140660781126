import styled from "styled-components"

const MultilineText = styled.div`
  white-space: pre-line !important;
  overflow-wrap: anywhere;
  p:last-child {
    margin-bottom: 0px;
  }
`

export { MultilineText }
